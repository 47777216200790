
.appNavbar {
	background-color: #282c34;
	height: var(--nav-height);
	display: flex;
	text-align: left;
	color: white;
	box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
}

.modalNav {
    color: white;
    font-size: 1.3rem;
}

.modalFlex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modalRightNav {
    margin-bottom: 2em;
}

.modalItem {
    margin: 1em 0;
}

.navContainer {
	display: flex;
	justify-content: space-between;
}

.hamburgerIcon {
	cursor: pointer;
	display: none;
}

.headerTitle {
	font-size: 1.5em;
	padding: 0.5em 0;
	margin-right: 2em;
}

.navRight, .navLeft {
	display: flex;
	align-items: center;
}

.active {
	position: relative;
}

.active::after {
	content: '';
	position: absolute;
    height: 2px;
    width: 80%;
    margin-top: 0.2em;
    background-color: var(--main-color);
}

.logout {
	cursor: pointer;
	display: flex;
	align-items: center;
}
.logoutIcon {
	margin-left: 0.25em;
}

.modalLogout {
    justify-content: center;
}

.hamburgerModal {
	background-color: #282c34;
	width: 50vw;
	height: 100vh;
	z-index: 120;
	position: fixed;
	left: 0;
	top: 0;
	transition: transform 0.3s ease-in-out;
	transform: translateX(-100%);
}
.hamburgerVisible {
	transform: translateX(0);
}

.hamburgerCloseBackdrop {
	width: 50vw;
	height: 100vh;
	position: fixed;
	left: 50%;
	top: 0;
	z-index: 200;
}

.closeIcon {
	cursor: pointer;
}
.closeIcon:after,
.closeIcon:before {
	content: '';
	height: 20px;
	width: 20px;
	border-top: 3px solid white;
	position: absolute;
	top: 18px;
	right: 5px;
	transform: rotate(-45deg);
}

.closeIcon:before {
	right: 19px;
	transform: rotate(45deg);
}

.closeIcon:hover {
	opacity: 0.7;
}



@media (max-width: 550px) {

	/* Nav */
	.hamburgerIcon {
		display: initial;
		position: absolute;
	}

	.navlink {
		display: none;
	}

	.navRight {
		display: none;
	}

	.navLeft {
		width: 100%;
		justify-content: space-between;
	}

	.headerTitle {
		margin: 0 auto;
	}
}