
.footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: 2em 0;
}

.footerItem {
    margin-right: 1em;
    cursor: pointer;
}

@media (max-width: 700px) {
    .footer {
        flex-direction: column;
    }

    .footerLeft {
        margin-bottom: 1.5em;
    }

    .footerRight {
        justify-content: center;
    }

    .footerItem {
        margin-right: 0.5em;
    }
}