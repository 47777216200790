.contentContainer {
	width: 80%;
}

.input {
	width: 100%;
	text-align: center;
	padding: 0 0.3em;
	height: 2.5em;
	font-size: 1rem;
	border: none;
	background: none;
	outline: none;
	box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15), -2px -2px 4px rgba(0, 0, 0, 0.03);
	margin-bottom: 1em;
}

.btnModal {
    margin-right: 1.5em;
}

.btnModal:last-of-type {
    margin-right: 0;
}

.habit-row:last-of-type {
	background-color: red;
}

.close {
	cursor: pointer;
}
.close:after,
.close:before {
	content: '';
	height: 20px;
	width: 20px;
	border-top: 1px solid #000;
	position: absolute;
	top: 13px;
	right: 0;
	transform: rotate(-45deg);
}

.close:before {
	right: 14px;
	transform: rotate(45deg);
}

.close:hover {
	opacity: 0.7;
}
