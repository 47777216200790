.background {
	background-color: #F2F2F2;
	background-image: url(../../../public/landing-bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.formContainer {
	width: 90%;
	min-height: 100vh;
	max-width: 1140px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.formDiv {
	width: 40%;
	box-shadow: 1px 0 20px rgba(0,0,0,.05);
	background-color: white;
	border-radius: 20px;
	padding: 2em 0;
}

/* ====AUTH FORM===== */
.authForm {
	padding: 0 2.5em;
}

.upperDiv {
	margin-bottom: 1em;
	padding-bottom: 1em;
	border-bottom: 1px solid silver;
}

.logo {
	font-size: 2rem;
	color: var(--main-color);
	margin-bottom: 0.5em;
}

.title {
	font-size: 1.7rem;
}

.textField {
	position: relative;
	border-bottom: 2px solid #adadad;
	margin: 1.85em 0;
}

.textField input {
	width: 100%;
	padding: 0 0.3em;
	height: 2.5em;
	font-size: 1rem;
	border: none;
	background: none;
	outline: none;
}

.textField label {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 5px;
	font-size: 1rem;
	color: #adadad;
	transition: top 0.5s;
	pointer-events: none;
}

.textField span::before {
	content: '';
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 0%;
	height: 2px;
	background: #2691d9;
	transition: width 0.5s;
}

.textField input:focus ~ label,
.textField input:valid ~ label {
	top: -10px;
	color: #2691d9;
}

.textField input:focus ~ span::before,
.textField input:valid ~ span::before {
	width: 100%;
}

.link {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 1rem;
	color: #2691d9;
}

.link:hover,
.link:focus {
	text-decoration: underline;
}

.errorDiv {
	margin-bottom: 0.5em;
}

.errorMessage {
	color: red;
}

/* ====Or Div==== */
.orDiv {
	display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin: 1em 0;
}

.orDiv::before,
.orDiv::after {
	content: "";
    flex: 0 1 100%;
    border-bottom: 1px solid rgb(193, 199, 198);
    margin: 0px 10px;
    transform: translateY(-50%);
}

.orSpan {
	font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    color: rgb(92, 108, 117);
    text-align: center;
}

/* ====Google Auth==== */
.googleLogo {
	height: 30px;
	margin-right: 10px;
}

.googleBtnText {
	font-size: 1rem;
	font-weight: 500;
}

/* ====Switch==== */
.switchLogin_signup {
	margin-top: 1.5em;
}

/* ====Demo==== */
.demoText {
	width: 80%;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 20px;
    color: var(--main-color);
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1.4rem;
}

.demoSuccess {
	width: 80%;
    margin: 0 auto;
    padding: 0.5em;
    border-radius: 20px;
	background-color: var(--main-color);
	color: white;
	font-weight: bold;
    margin-bottom: 1em;
    font-size: 1.4rem;
}


@media (max-width: 1000px) {
	.formDiv {
		width: 60%;
	}
}

@media (max-width: 630px) {
	.formDiv {
		width: 90%;
	}
}