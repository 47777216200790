/* === Animations
============ */
@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px)
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

/* === Layout
============ */
.container {
    width: 80%;
    max-width: 1500px;
    margin: 0 auto;
    font-family: Manrope;
    color: #242739;
}

/* === Common
============ */
.mainColor {
    color: var(--main-color);
}

/* === BTNs */
.btn {
    border: none;
	outline: none;
	cursor: pointer;
	font-family: inherit;
    border-radius: 20px;
    font-size: 1rem;
    padding: 0.5em 1.25em;
}

.btn:hover,
.btn:focus {
	opacity: 0.9;
}

.btnInvisible {
    background-color: transparent;
    color: black;
}

.btnPrimary {
    background-color: transparent;
    color: var(--main-color);
    border: 2px solid var(--main-color);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.btnPrimary:hover {
    background-color: var(--main-color);
    color: white;
}

/* === icons */
.icon {
    width: 21px;
    height: 21px;
    fill: var(--main-color);
    margin-bottom: 1em;
}

/* === styling */
.underline::after {
    content: '';
    display: block;
    height: 3px;
    width: 35%;
    margin: 0 auto;
    margin-top: 0.1em;
    background-color: var(--main-color);
}

/* === Nav
============ */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2em 0;
    margin-bottom: 8.5em;
}

.title {
    color: var(--main-color);
    font-size: 1.4rem;
}


/* === Hero
============ */
.heroDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 11em;
}

.heroLeftDiv {
    width: 50%;
    animation: slideLeft 0.55s ease-in-out;
    animation-fill-mode: backwards;
    animation-delay: 0.1s;
}

.heroTitle {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 0.3em;
}

.heroSubtitle {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 1em;
}

.btnHero {
    font-size: 1.2rem;
    background-color: var(--main-color);
    color: white;
}

.heroSVG {
    width: 540px;
}

/* === Break Area 1
============ */
.areaOneDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 10em;
    opacity: 0;
}

.breakOneIntersected {
    opacity: 1;
    animation: slideUp 0.4s ease-in-out;

}

.cardOneDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 33.33%;
    padding: 0 3em;
}

.cardOneDiv:last-of-type {
    margin-right: 0;
}

.cardOneTitle {
    margin-bottom: 0.5em;
}

.googleIcon {
  fill: var(--main-color);
  margin-bottom: 1rem;
}


/* === CTAAreoOne
============ */
.ctaOneDiv {
    margin-bottom: 9em;
}

.bodyTitle {
    width: 63%;
    margin: 0 auto;
    font-size: 2.4rem;
    font-weight: 800;
    margin-bottom: 0.3em;
}

.bodySubTitle {
    font-size: 1.4rem;
    margin-bottom: 1.5em;
}

.btnCTA {
    display: block;
    margin: 0 auto;
    margin-bottom: 1em;
    font-size: 1.25rem;
}

.taskListSVG {
    width: 650px;
}

/* === how To Area
============ */
.howToDiv {
    margin-bottom: 9em;
    opacity: 0;
}

.howToIntersected {
    opacity: 1;
    animation: slideUp 0.4s ease-in-out;
}

.howToTitleDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.howToTitle {
    display: inline-block;
    margin-bottom: 1.5em;
}

.howToCardsDiv {
    padding: 0 5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4em;
}

.howToCardDiv {
    padding: 1.5em;
    text-align: center;
    border-radius: 15px;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.15), -2px -2px 4px rgba(0,0,0,0.03);
}

.howToCardNumber {
    color: var(--main-color);
    margin-bottom: 0.4em;
}

.howToCardTitle {
    margin-bottom: 1em;
}


/* === In Action Area
============ */
.inActionDiv {
    margin-bottom: 9em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.laptopDiv {
    position: relative;
    width: 1280px;
    height: 813px;
}

.laptop {
    position: absolute;
    top: 0;
    left: 0;
    width: 1280px;
    height: 813px;
    z-index: 5;
}

.laptopDivMargin {
    position: relative;
    margin-left: 166px;
    margin-top: 111px;
}

.imgScreenshot {
    width: 950px;
    height: 592px;
    position: absolute;
    top: -1px;
    left: -1px;
}

.phoneMockup {
    position: absolute;
    top: 70px;
    right: -40px;
    height: 650px;
    z-index: 10;
}

.phoneStatusBarMockup {
    position: absolute;
    top: 102px;
    right: 35px;
    height: 24px;
    z-index: 9;
}

.imgScreenshotPhone {
    width: 269px;
    height: 563px;
    position: absolute;
    top: 125px;
    left: 807px;
    z-index: 6;
}

/* =================================
======= Preview Sliding animation */
@keyframes fadeO {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    30% {
        transform: translateX(0);
        opacity: 1;
    }
    33% {
        transform: translateX(-17px);
        opacity: 0;
    }
    97% {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
.img1 {
    animation: fadeO 9.9s ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 6.6s;
}
.img2 {
    animation: fadeO 9.9s ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 3.3s;
}

.img3 {
    animation: fadeO 9.9s ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 0s;
}
/* http://css3.bradshawenterprises.com/cfimg/
For "n" images You must define:
a=presentation time for one image : 3
b=duration for cross fading : 0.3
Total animation-duration is of course t=(a+b)*n
animation-delay = t/n or = a+b

Percentage for keyframes:
0%
a/t*100%
(a+b)/t*100% = 1/n*100%
100%-(b/t*100%)
100% */


/* === Last CTA Area
============ */
.ctaLastDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-bottom: 5em;
}

.ctaLastLeftDiv {
    width: 50%;
    margin-right: 2em;
}

.btnLastCTA {
    font-size: 1.2rem;
}

.activitySVG {
    width: 450px;
}


@media (min-width: 1750px){
    .cardOneDiv {
        padding: 0 5em;
    }
}

@media (max-width: 1400px){
    /* Hero */
    .heroTitle {
        font-size: 3rem;
    }
    .heroSubtitle {
        font-size: 1.25rem;
    }

    /* In action preview*/
    .laptopDiv {
        position: relative;
        width: 1024px;
        height: 650px;
    }
    
    .laptop {
        position: absolute;
        top: 0;
        left: 0;
        width: 1024px;
        height: 650px;
        z-index: 5;
    }
    
    .laptopDivMargin {
        position: relative;
        margin-left: 133px;
        margin-top: 88px;
    }
    
    .imgScreenshot {
        width: 758px;
        height: 473px;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .phoneMockup {
        position: absolute;
        top: 70px;
        right: -40px;
        height: 520px;
        z-index: 10;
    }
    
    .phoneStatusBarMockup {
        position: absolute;
        top: 98px;
        right: 22px;
        height: 19px;
        z-index: 9;
    }
    
    .imgScreenshotPhone {
        width: 215px;
        height: 450px;
        position: absolute;
        top: 116px;
        left: 653px;
        z-index: 6;
    }
}

@media (max-width: 1000px){
    .nav {
        margin-bottom: 3em;
    }
    .heroDiv {
        flex-direction: column;
    }
    .heroLeftDiv, .heroRightDiv {
        width: 100%;
    }
    .heroLeftDiv {
        text-align: center;
        margin-bottom: 4em;
    }
    .heroRightDiv {
        display: flex;
        justify-content: center;
    }
}


/* Mobile */
@media (max-width: 700px) {
    .container {
        width: 85%;
    }

    .nav {
        margin-bottom: 3.5em;
    }

    /* Hero */
    .heroDiv {
        margin-bottom: 4em;
    }

    .heroTitle {
        font-size: 2.25rem;
    }
    .heroSubtitle {
        font-size: 1rem;
        margin-bottom: 2em;
    }

    .heroLeftDiv {
        margin-bottom: 7em;
    }

    .heroSVG {
        height: 220px;
        width: 330px;
    }

    /* Area one */
    .areaOneDiv {
        flex-direction: column;
        align-items: center;
        margin-bottom: 6em;
    }
    .cardOneDiv {
        padding: 0;
        width: 80%;
        margin-bottom: 2em;
    }

    /* CTA one*/
    .bodyTitle {
        font-size: 1.8rem;
        width: 80%;
    }
    .taskListSVG {
        width: 300px;
    }

    /* How to area */
    .howToCardsDiv {
        grid-template-columns: 1fr;
        gap: 3em;
    }

    /* In action area */
    .laptopDiv {
        width: 361px;
        height: 550px;
        position: relative;
    }
    
    .laptop {
        display: none;
    }
    
    .laptopDivMargin {
        margin: 0;
        margin-top: 2.5em;
    }
    
    .imgScreenshot {
        display: none;
    }
    
    .phoneMockup {
        position: absolute;
        top: 0;
        right: 0;
        height: 550px;
        z-index: 10;
    }
    
    .phoneStatusBarMockup {
        position: absolute;
        top: 29px;
        left: 63px;
        height: 20px;
        z-index: 9;
    }
    
    .imgScreenshotPhone {
        width: 228px;
        height: 478px;
        position: absolute;
        top: 45px;
        left: 66px;
        z-index: 6;
    }

    /* CTA last */
    .ctaLastDiv {
        flex-direction: column;
    }
    .ctaLastLeftDiv {
        width: 100%;
        text-align: center;
        margin-right: 0;
        margin-bottom: 2em;
    }
    .activitySVG {
        width: 300px;
    }
}