.heroDiv {
	margin-top: 3em;
	margin-bottom: 1.5em;
}

.moreInfoParagraph {
	text-align: left;
}

/* ================
More infos MODAL
================= */
.infosModal {
    position: fixed;
	text-align: center;
	width: 54%;
	left: 23%;
	height: 80vh;
	top: 10vh;
	padding: 1em 0;
	z-index: 100;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	background-color: white;
	overflow-y: scroll;
}

.verticalAlign {
	vertical-align: middle;
}

.close {
	cursor: pointer;
}
.close:after,
.close:before {
	content: '';
	height: 30px;
	width: 30px;
	border-top: 3px solid #000;
	position: absolute;
	top: 23px;
	right: 10px;
	transform: rotate(-45deg);
}

.close:before {
	right: 31px;
	transform: rotate(45deg);
}

.close:hover {
	opacity: 0.7;
}

.modalPartDiv {
	width: 75%;
	margin: 0 auto;
	margin-top: 2em;
	padding-bottom: 1em;
	border-bottom: 1px solid gray;
}

.infoIcon {
	font-size: 3rem;
	color: var(--main-color);
	margin-bottom: 0.2em;
}

.partTitle {
	text-transform: uppercase;
	font-size: 1.4rem;
	margin-bottom: 0.6em;
}

.infoParagraph {
	margin-bottom: 0.4em;
}

@media (max-width: 550px) {
	.infosModal {
		width: 84%;
		left: 8%;
	}
}