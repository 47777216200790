
.background {
    background-color: rgb(247,249,252);
    background-color: rgb(242,242,242);
    background-color: rgb(244,245,250);
    /* background-image: linear-gradient(rgb(244,245,250), rgb(246, 247, 252) 100%); */
    padding-top: 3em;
    padding-bottom: 4em;
    min-height: calc(100vh - var(--nav-height));
}

.topStatsDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
}

.topStatsContainer {
    width: 90%;
    max-width: 920px;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    background-color: white;
    padding: 1.5em;
    border-radius: 10px;
}

.topTitleDiv{
    margin-bottom: 1em;
}

.topContentDiv {
    display: flex;
    justify-content: center;
}

.textStats {
    display: flex;
}
.statBoxDiv {
    background-color: var(--main-color);
    color: white;
    /* font-weight: bold; */
    font-size: 0.9rem;
    padding: 1em 2em;
    border-radius: 10px;
}

.neutralColor {
    background-color: #9B9EA3;
    color: white;
}
.greenColor {
    background-color: #2CABE3;
    color: white;
}
.redColor {
    background-color: #FFC36D;
    color: white;
}

.marginRight {
    margin-right: 1em;
}

.bottomStatsDiv {
    display: flex;
    justify-content: center;
}

.bottomStatsContainer {
    width: 90%;
    max-width: 920px;
    display: flex;
    justify-content: space-between;
}

.pieDiv,
.barChartDiv {
    padding: 1em;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-radius: 10px;
    background-color: white;
}

.pieTextStats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.barChart {
    height: 250px;
    margin-top: 100px;
    display: flex;
    align-items: baseline;
}

.barTextStats {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
}


@media (max-width: 600px) {

    .pieTextStats {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .topContentDiv {
        flex-direction: column;
    }

    .statBoxDiv {
        margin: 0 1em 1em 1em;
    }

    .bottomStatsContainer {
        flex-direction: column;
    }

    .chartDiv {
        display: flex;
        justify-content: center;
    }

    .pieDiv {
        margin-bottom: 3em;
    }

}