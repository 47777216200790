:root {
	--main-color: #20C98B;
	--nav-height: 56px;
}

html, * {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

table {
	border-collapse: collapse;
}

th {
	border: 1px solid white;
	/*? debatable, put border top again ? */
	border-top: none;
}

a {
	text-decoration: none;
	color: inherit;
}

h4, h3, h2, h1, p {
	margin: 0;
}

li {
	list-style-type: none;
	margin-right: 1em;
}

.App {
	text-align: center;
}

/* =====
COMMON 
==== */
.center-container{
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn {
	border: none;
	outline: none;
	cursor: pointer;
	font-family: inherit;
	background-color: #282c34;
	color: white;
	padding: 0.5em 1.25em;
	border-radius: 5px;
}
.btn:hover,
.btn:focus {
	opacity: 0.9;
}

.btn-ghost {
	border-radius: 20px;
	background-color: white;
	border: 2px solid var(--main-color);
	color: var(--main-color);
	transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out
}

.btn-icon {
	border: none;
	cursor: pointer;
	background-color: transparent;
	padding: 0.5em;
}
.btn-icon:hover,
.btn-icon:focus {
	opacity: 0.7;
}

.btn-how {
	margin-left: 1.5em;
	background-color: var(--main-color);
}

.btn-how-modal {
    margin: 0;
    font-size: 1.2rem;
}

.btn-sign {
    border-radius: 20px;
    font-size: 1rem;
    background-color: var(--main-color);
    color: white;
}

.btn-auth {
	width: 100%;
	font-size: 1.3rem;
	font-weight: bold;
	margin-bottom: 0.5em;
	border-radius: 25px;
}
.btn-auth:disabled {
	opacity: 0.5;
	cursor:initial;
}
.btn-auth:disabled:hover {
	opacity: 0.5;
	cursor:initial;
}

.btn-small {
	font-size: 0.75rem;
}

.btn-big {
	font-size: 1.25rem;
}

.btn-no-click {
	cursor: initial;
}
.btn-no-click:hover {
	opacity: 1;
}

.container {
	width: 90%;
	max-width: 1600px;
	margin: 0 auto;
}

.flex {
	display: flex;
}

.no-margin {
	margin: 0;
}

/* ==== Modals ==== */
@keyframes backdrop-opacity {
	0% {
		opacity: 0;
	}
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 50;
	background-color: rgb(0, 0, 0);
	opacity: 0.8;
	animation-name: backdrop-opacity;
	animation-duration: 0.3s;
}

.backdrop-light {
	opacity: 0.65;
	animation: none;
}

.editModal {
	position: absolute;
	width: 100%;
	height: 125px;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -115%;
	z-index: 100;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	background-color: white;
}

.habit-row:last-of-type th .editModal {
	top:initial;
	bottom: 0;
}

/* ==== Other ==== */
.main-color {
	color: var(--main-color);
	font-weight: bold;
}

/* =====
Forms
==== */
/* ====NEW HABIT FORM==== */
.habit-form {
	min-width: 380px;
	padding: 0.5em;
	margin: 0 auto;;
}

.habit-input {
	width: 290px;
	margin-right: 1em;
}
.habit-input:focus {
	outline: none;
}
.habit-input::placeholder {
	font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif;
}

.btn-add-habit {
	padding: 0.3em 0.75em;
}
.btn-add-habit:disabled {
	opacity: 0.7;
}

/* =====
Table 
==== */
.relative {
	position: relative;
}

.background-div {
	position: absolute;
	right: 0;
	z-index: -1;
}

@media (min-width: 1800px){
	.background-div{
		right: 100px;
	}
}


.background-svg {
	width: 250px;
}
@media (max-width: 1520px){
	.background-svg {
		width: 130px;
	}
}

@media (max-width: 1380px){
	.background-svg {
		display: none;
	}
}

.habit-table {
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	background-color: white;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	margin-top: 5em;
	margin-bottom: 6em;
}

.table-first-row {
	background-color: #282c34;
	color: white;
}

.table-first-row > * {
	border: none;
}

.table-habit-title-cell {
	width: 500px;
}

.table-habit-title {
	font-size: 1.25em;
}

.days-small {
	font-size: 0.65em;
}

.table-day-cell {
	width: 30px;
	padding: 0.8em 0;
}

.table-day-cell:last-of-type {
	padding-right: 0.3em;
}

.habit-row {
	border-bottom: 1px solid #dddddd;
}

.habit-row:last-of-type {
	border-bottom: 1px solid #282c34;
}

.habit-row:last-of-type > th {
	border-bottom: 1px solid #282c34;
}

.habit-row:last-of-type > .habit-row-infos {
	border-bottom: none;
}

.habit-row-infos {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fa-star {
	position: absolute;
    left: 12%;
    transform: rotate(20deg);
    color: var(--main-color);
    opacity: 0.3;
    font-size: 1.9rem;
}

.th-habit-name {
	padding: 0.5em 0.5em;
	cursor: pointer;
}

.th-habit-name:hover {
	opacity: 0.87;
}

.th-undo-clear-div {
	display: flex;
}

.th-undo-btn {
	border-bottom: 1px solid #dddddd;
}

.green-counter,
.red-counter {
	font-weight: 600;
	margin: 0;
	padding: 0.5em;
}

.green-counter{
	color: var(--main-color);
	filter: brightness(0.85);
}

.red-counter{
	color: red;
}

.th-plus-minus-div {
	display: flex;
}

.btn-minus {
	color: red;
}

.btn-plus {
	color: var(--main-color);
	filter: brightness(0.85);
}

.btn-undo {
	padding: 0;
}

.btn-google {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	color: rgb(92, 108, 117);
	box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 4px;
	border-radius: 25px;
	margin-bottom: 0.5em;
}

.habitCell{
	border-bottom: 1px solid #dddddd;
}

.habitCellIcon {
	width: 24px;
}

.successColor {
	stroke: var(--main-color);
	filter: brightness(0.85);
}

.failColor {
	stroke: red;
}

/* =====
Other common 
==== */
.no-padding {
	padding: 0;
}



@media (max-width: 550px) {

	/* Table */
	.habit-table {
		margin: 0 auto;
		margin-top: 5em;
		margin-bottom: 6em;
	}
	
	.table-day-cell {
		display: none;
	}

	.table-habit-title-cell {
		width: 300px;
	}

	.habit-row {
		display: flex;
		flex-direction: column;
	}

	.table-habit-title {
		padding: 0.5em 0;
	}

	.green-counter,
	.red-counter {
		padding: 0.5em 0.2em;
	}

	.btn-icon {
		padding: 0.5em 0.3em;
	}

	.habit-form {
		min-width: 200px;
	}

	.habit-input {
		margin-top: 0.3em;
		margin-bottom: 0.3em;
	}
	.habit-input::placeholder {
		text-align: center;
	}

	
}